import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import BrandycurrentFeed from "../components/brandycurrentfeed"
import BrandypasteventsFeed from "../components/brandypasteventsfeed"

const BrandyHub = () => (

    <Layout>
        <SEO title="Brandy Collection" />
    
        <PostHero
            title="Brandy Collection"
            description="The distilled fruit spirit"
            image={require('../images/rajesh-rajput-y2MeW00BdBo-unsplash.jpg')}
        />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">
                <BrandycurrentFeed />
            </div>

            <div className="hub-section-title center">
                <h2>Recent Brandy Events</h2>
            </div>
            <div className="hub-cards-section">
                <BrandypasteventsFeed />
            </div>

         </div>

      </div>

  </Layout>
)

export default BrandyHub



/* 

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import HubCard from "../components/hubcard"
import { Link } from "gatsby"

const BrandyHub = ({data}) => (

  <Layout>
    <SEO title="Brandy Collection" />
  
    <PostHero
        title="Brandy Collection"
        description="The distilled fruit spirit"
        image={require('../images/rajesh-rajput-y2MeW00BdBo-unsplash.jpg')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

        <div className="topline-statement">
            <p>We are currently looking for a <Link to="/careers">Director of Brandy Curation and Education</Link>. </p>
        </div>

            <div className="hub-cards-section">

                {data.allContentfulCard.edges.map(edge => (
                    <HubCard
                        key={edge.node.id}
                        image={edge.node.image.fluid.src}                        
                        title={edge.node.title}    
                        contenttype={edge.node.contentType.title}
                        date={edge.node.date}
                        url={edge.node.url}
                        desc={edge.node.description.childMarkdownRemark.html}
                        alttext={edge.node.image.title}
                    />
                ))}

            </div>

         </div>

      </div>

  </Layout>
)

export default BrandyHub


export const query = graphql`
{
    allContentfulCard(sort: {fields: createdAt, order: DESC}, filter: {spiritType: {title: {eq: "Brandy"}}}) {
        edges {
            node {
                title       
                url
                date
                contentType {
                    title
                }
                description {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    title
                    fluid(maxWidth: 1200, quality: 85) {
                        src
                        ...GatsbyContentfulFluid
                    }
                }
                spiritType {
                    title
                }
                id
                createdAt        
            }
        }
    }
}
`
 */