import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import HubCard from "./hubcard"

export default () => (
    <StaticQuery
        query={graphql` 
            query BrandycurrentfeedQuery {
                allContentfulCard(
                    sort: {
                        fields: updatedAt, order: DESC
                    },
                    filter: {
                        spiritType: {title: {eq: "Brandy"}},
                        pastEvent: {ne: true}
                    } ) {
                    edges {
                        node {
                            title       
                            url
                            date
                            contentType {
                                title
                            }
                            description {
                                childMarkdownRemark {
                                    html
                                }
                            }
                            image {
                                title
                                fluid(maxWidth: 1200, quality: 85) {
                                    src
                                    ...GatsbyContentfulFluid
                                }
                            }
                            spiritType {
                                title
                            }
                            id
                            dateOrder  
                            eventsPage
                            pastEvent       
                        }
                    }
                }  
            }
        `}

      render={data => (
        <>
            {data.allContentfulCard.edges.map(edge => (

                <HubCard
                    key={edge.node.id}
                    image={edge.node.image.fluid.src}                        
                    title={edge.node.title}    
                    contenttype={edge.node.contentType.title}
                    date={edge.node.date}
                    url={edge.node.url}
                    desc={edge.node.description.childMarkdownRemark.html}
                    alttext={edge.node.image.title}
                />
            
            ))}
        </>
      )}
    />
  )